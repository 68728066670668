<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="mt-2">
      Editor de correos
      <template v-slot:actions>
        <v-icon color="primary" @click.native.stop="reloadp++">
          mdi-refresh
        </v-icon>
        <v-icon
          color="error"
          @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })"
        >
          mdi-close
        </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card>
        <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
          <v-tabs-slider color="red"></v-tabs-slider>

          <v-tab href="#tab-1">
            Agregar
            <v-icon>mdi-email-plus-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <v-card class="white" flat>
              <v-card-text class="email-wrapper">
                <v-card-text>
                  <ManageEmail :key="reloadp" :email="email" :editMood="false"></ManageEmail>
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ManageEmail from "./ManageEmail";

export default {
  name: "email",
  components: {
    ManageEmail,
  },
  props: ["win"],
  data: () => ({
    email: {
      EmailName: "",
      EmailTarget: null,
      Email_Body: {},
      Email_Id: "",
      ID_User: "",
      State: "",
    },
    tab: null,
    reloadp: 0,
  }),
  methods: {
    reloadData(payload) {
      this.$emit("reloadData", payload);
    },
  },
};
</script>

<style scoped></style>
