<template>
  <ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
    <v-form @submit.prevent="handleSubmit(save)">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <ValidationProvider name="Email Name" rules="required" v-slot="{ errors }">
            <v-text-field outlined dense 
              :error-messages="errors"
              v-model="form.Email_Name"
              label="Nombre del correo"
            >
            </v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <ValidationProvider name="State" rules="required" v-slot="{ errors }">
            <v-select outlined dense  attach
              :error-messages="errors"
              :items="states"
              item-text="name"
              item-value="value"
              label="Estado"
              v-model="form.State"
            ></v-select>
          </ValidationProvider>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="form.Email_Body">
          <v-btn @click="open" :color="!isEmpty(form.Email_Body) ? 'success' : 'grey'">{{
            !isEmpty(form.Email_Body)
              ? editMood
                ? "Tienes una plantilla, haz clic para editar"
                :"Tienes una nueva plantilla"
              : "No tiene una plantilla, debes agregarla"
          }}</v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-else>
          <v-btn @click="open" color="grey">You haven't a template, must add it.</v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-row justify="end">
            <v-btn
              color="success darken-1"
              type="submit"
              :loading="loading_btn"
              :disabled="invalid || isEmpty(form.Email_Body) || loading_btn"
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="auto">
        <v-card>
          <v-card-title class="text-h5"> {{ formTitle }}</v-card-title>
          <v-card-text v-if="form.Email_Body">
            <v-row>
              <v-col cols="12" md="12">
                <EmailEditor
                  :appearance="appearance"
                  :min-height="minHeight"
                  :project-id="projectId"
                  :display-mode="displayMode"
                  :locale="locale"
                  :tools="tools"
                  :options="options"
                  ref="emailEditor"
                  v-on:load="editorLoaded"
                  v-on:ready="editorReady"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error darken-1" text @click="close"> Cerrar </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success darken-1" text @click="exportData">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </ValidationObserver>
</template>

<script>
import _ from "lodash";
import { EmailEditor } from "vue-email-editor";
export default {
  name: "ManageEmail",
  props: ["email", "win", "editMood"],
  components: { EmailEditor },
  data() {
    return {
      form: {},
      dialog: false,
      //   editMood: false,
      loading_btn: false,
      states: [
        { name: "Activo", value: "1" },
        { name: "Inactivo", value: "0" },
      ],
      emailBody: {
        html: "",
        design: "",
      },
      defaultTemp: {
        html: "",
        design: "",
      },
      minHeight: "1000px",
      locale: "en",
      projectId: 0, // replace with your project id
      displayMode: "email",
      tools: {
        // disable image tool
        image: {
          enabled: false,
        },
      },
      options: {},
      appearance: {
        // theme: 'dark',
        panels: {
          tools: {
            dock: "right",
          },
        },
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close;
    },
  },
  computed: {
    formTitle() {
      return this.editMood ? "Cambiar plantilla actual" :"Agregar nueva plantilla";
    },
  },
  mounted() {
    this.fetchCurrentTemp();
  },
  methods: {
    fetchCurrentTemp() {
      let { Email_Id, EmailName, State, Email_Body } = JSON.parse(
        JSON.stringify(this.email)
      );
      if (this.editMood) {
        this.editMood = true;
        this.form.Email_Id = Email_Id;
        this.form.Email_Name = EmailName;
        this.form.Email_Body = JSON.parse(Email_Body);
        this.form.State = State === "Active" || State === 1 || State === "1" ? "1" : "0";
        this.form.userId = JSON.parse(localStorage.getItem("user")).id;
      } else {
        this.form.Email_Id = "";
        this.form.Email_Name = "";
        this.form.Email_Body = Email_Body;
        this.form.State = "";
        this.form.userId = JSON.parse(localStorage.getItem("user")).id;
      }
    },
    isEmpty(data) {
      return _.isEmpty(data);
    },
    reloadData(payload) {
      this.$emit("reloadData", payload);
    },
    open() {
      this.editMood
        ? (this.emailBody = JSON.parse(JSON.stringify(this.form.Email_Body)))
        : "";

      this.dialog = true;
    },
    close() {
      this.emailBody = Object.assign({}, this.defaultTemp);
      this.dialog = false;
    },

    editorLoaded() {
      console.log("editorLoaded");
      this.editMood && this.$refs.emailEditor.editor.loadDesign(this.emailBody.design);
      // Pass your template JSON here
    },
    // called when the editor has finished loading
    editorReady() {
      console.log("editorReady");
    },
    exportData() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.form.Email_Body = { design: data.design, html: data.html };
        console.log("exportHtml", data);
        this.close();
      });
    },
    async save() {
      this.$refs.observer.validate();
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.loading_btn = true;
        if (this.editMood) {
          let obj = {
            emailId: this.form.Email_Id,
            emailBody: JSON.stringify({
              html: this.form.Email_Body.html,
              design: this.form.Email_Body.design,
            }),
            State: this.form.State,
            Email_Name: this.form.Email_Name,
            userId: this.form.userId,
          };
          this.$API.email
            .updateEmail(obj)
            .then((response) => {
              this.loading_btn = false;
              this.$refs.observer.reset();
              this.$toasted.success(response.message);
              this.reloadData("email");
            })
            .catch((err) => {
              this.loading_btn = false;
              console.log(err);
            });
        } else {
          let obj = {
            emailBody: JSON.stringify({
              html: this.form.Email_Body.html,
              design: this.form.Email_Body.design,
            }),
            State: this.form.State,
            Email_Name: this.form.Email_Name,
            userId: this.form.userId,
          };
          this.$API.email
            .addEmail(obj)
            .then((response) => {
              this.loading_btn = false;
              this.$refs.observer.reset();
              this.$toasted.success(response.message);
              this.reloadData("email");
            })
            .catch((err) => {
              this.loading_btn = false;
              console.log(err);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.temp-dimensions {
  max-width: 100px;
  max-height: 300px;
}

.unlayer-editor {
  height: 1000px !important;
}
.unlayer-editor iframe {
  min-width: auto !important;
}
</style>
